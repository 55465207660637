<template>
  <div class="superbo">
    <div class="colLeft">
      <div class="e">
        <span @click="$router.go('-1')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
         </span>
      </div>
      <div class="e">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M2,16H22V18C22,19.11 21.11,20 20,20H4C2.89,20 2,19.11 2,18V16M6,4H18C20.22,4 22,5.78 22,8V10H2V8C2,5.78 3.78,4 6,4M4,11H15L17,13L19,11H20C21.11,11 22,11.89 22,13C22,14.11 21.11,15 20,15H4C2.89,15 2,14.11 2,13C2,11.89 2.89,11 4,11Z" /></svg>
        </span>
      </div>
    </div>
    <div class="colNav">
      <div class="title">Restaurants</div>
      
      <a class="e">Liste des restaurants</a>
      <a v-if="$store.state.user.user.boutique" @click="reset_boutique_me()" class="e">Me retirer de mon restaurant</a>
      <a @click="go_add_boutique()" class="e">Ajouter un restaurant</a>
    </div>
    <div class="colMain">
      <div class="title">Liste des restaurants</div>

      <el-table
          :data="boutiques"
          style="width: 100%">
        <el-table-column width="260" prop="name" label="Nom"></el-table-column>
        <el-table-column width="220" label="Email"><template slot-scope="scope"><a :href="'mailto://' + scope.row.email">{{scope.row.email}}</a></template></el-table-column>
        <el-table-column width="130" label="Tél"><template slot-scope="scope"><a :href="'tel://' + scope.row.national_phone_number">{{scope.row.national_phone_number}}</a></template></el-table-column>
        <el-table-column label="Actif"><template slot-scope="scope"><span class="actif" :data-active="scope.row.active"></span></template></el-table-column>
        <el-table-column label="Ouvert"><template slot-scope="scope"><span class="actif" :data-active="scope.row.online"></span></template></el-table-column>
        
        <el-table-column align="center" label="Commandes" prop="commandes_nb"></el-table-column>
        <el-table-column align="center" label="Total"><template v-if="scope.row.commandes_sum" slot-scope="scope">{{ scope.row.commandes_sum | formatNumberdecimal }}€</template></el-table-column>
        <el-table-column align="center" label="Moyenne / commande"><template v-if="scope.row.commandes_moyenne" slot-scope="scope">{{ scope.row.commandes_moyenne | formatNumberdecimal }}€</template></el-table-column>
        <el-table-column align="center" label="Vues" prop="view_nb"></el-table-column>
        
        <el-table-column width="70" align="right"><template slot-scope="scope"><div class="btn" @click="setAdmin(scope.row.id)">
          <svg data-v-305ed79d="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path data-v-305ed79d="" d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z"></path></svg>
        </div></template></el-table-column>
      </el-table>

      <div class="set_user_boutique">

        <el-select v-model="boutique_select" placeholder="Select Boutique">
          <el-option
              v-for="item in boutiques"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="user_select" placeholder="Select User">
          <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.nom + ' ' + item.prenom"
              :value="item.id">
          </el-option>
        </el-select>
        <button class="simple" @click="set_user_boutique">Attribuer</button>
      </div>
      
    </div>
    
    <div class="colRight">
      <div class="title">Statistiques</div>
      
      <div v-if="Object.keys(global_stats).length">
        <div class="sub">Global - Evolution d-1</div>
        <div class="e">Clients <span>{{ global_stats.clients_nb }}<i class="variation" :data-negative="clients_variation.negative">{{ clients_variation.value }}</i></span></div>
        <div class="e">Restaurants <span>{{ global_stats.boutiques_nb }}<i class="variation" :data-negative="boutiques_variation.negative">{{ boutiques_variation.value }}</i></span></div>
        <div class="e">Restaurants actifs <span>{{ global_stats.boutiques_active_nb }}<i class="variation" :data-negative="boutiques_active_variation.negative">{{ boutiques_active_variation.value }}</i></span></div>
        <div class="e">Commandes <span>{{ global_stats.commandes_nb }}<i class="variation" :data-negative="commandes_variation.negative">{{ commandes_variation.value }}</i></span></div>
        
        <div class="sub">Aujourd'hui</div>
        <div class="e">Clients <span>{{ global_stats.clients_nb_t }}</span></div>
        <div class="e">Restaurants <span>{{ global_stats.boutiques_nb_t }}</span></div>
        <div class="e">Restaurants actifs <span>{{ global_stats.boutiques_active_nb_t }}</span></div>
        <div class="e">Commandes <span>{{ global_stats.commandes_nb_t }}</span></div>
        
      </div>
      
    </div>
    
  </div>
</template>

<script>
export default {
  name: "superbo",
  data() {
    return {
      boutique_select: null,
      user_select: null,
      
      users: {},
      boutiques: {},
      global_stats: {},
      tableData: [{
        date: '2016-05-03',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles'
      }, {
        date: '2016-05-02',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles'
      }, {
        date: '2016-05-04',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles'
      }, {
        date: '2016-05-01',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles'
      }]
    }
  },
  computed: {
    clients_variation() {
      let v = parseInt(this.global_stats.clients_nb_t) - parseInt(this.global_stats.clients_nb_y)
      if(v >= 0) return { negative: false, value: '+' + v }
      else return { negative: true, value: v }
    },
    commandes_variation() {
      let v = parseInt(this.global_stats.commandes_nb_t) - parseInt(this.global_stats.commandes_nb_y)
      if(v >= 0) return { negative: false, value: '+' + v }
      else return { negative: true, value: v }
    },
    boutiques_variation() {
      let v = parseInt(this.global_stats.boutiques_nb_t) - parseInt(this.global_stats.boutiques_nb_y)
      if(v >= 0) return { negative: false, value: '+' + v }
      else return { negative: true, value: v }
    },
    boutiques_active_variation() {
      let v = parseInt(this.global_stats.boutiques_active_nb_t) - parseInt(this.global_stats.boutiques_active_nb_y)
      if(v >= 0) return { negative: false, value: '+' + v }
      else return { negative: true, value: v }
    }
  },
  created() {
    this.$http.get('/superbo/boutiques').then((b) => {
      this.boutiques = b.data
    }).catch(() => {
      this.$router.push('/')
    })
    this.$http.get('/superbo/global_stats').then((b) => {
      this.global_stats = b.data
    })
    this.$http.get('/superbo/users').then((b) => {
      this.users = b.data
    })
  },
  methods: {
    setAdmin(boutique_id) {
      this.$http.post('/superbo/setadmin/', { boutique_id: boutique_id }).then(() => {
        location.href = '/backoffice/commandes/enattente'
      })
    },
    reset_boutique_me() {
      this.$http.post('/superbo/setadmin/', { boutique_id: null }).then(() => {
        location.reload()
      })
    },
    go_add_boutique() {
      this.$http.post('/superbo/setadmin/', { boutique_id: null }).then(() => {
        location.href = '/boutique/creation'
      })
    },
    set_user_boutique() {
      this.$http.post('/superbo/setadmin/user', { boutique_id: this.boutique_select, user_id: this.user_select }).then(() => {
        location.reload()
      })
    }
  }
}
</script>
<style>
  .superbo .el-table, .superbo .el-table tr, .superbo .el-table td,.superbo .el-table th { background: none!important; border: none; box-shadow: rgba(4, 4, 5, 0.2) 0px 1px 0px; color: var(--white-interactive) }
  .superbo .el-table thead th { color: #8e9297 !important }
  .superbo .el-table .cell { padding-left: 16px!important; line-height: 18px!important; }
  .superbo .el-table:before { height: 0;}
  .superbo .el-table td { padding: 8px 0 4px 0 }
</style>

<style scoped>
  .superbo { background: #36393f; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000; display: flex; color: var(--white-main); fill: var(--white-main);
    --white-interactive: #b9bbbe; overflow: auto;
  }
  .superbo .colLeft { background: #202225; width: 72px; flex: 0 0 auto; display: flex; flex-direction: column; padding-top: 6px; }
  .superbo .colLeft .e { width: 72px; height: 62px; display: flex; align-items: center; justify-content: center; cursor: pointer }
  .superbo .colLeft .e span { width: 48px; height: 48px; background: #36393f; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
  
  .superbo .colNav { background: #2f3136; width: 240px; flex: 0 0 auto }
  .superbo .colNav .title { height: 48px; display: flex; align-items: center; box-shadow: rgba(4, 4, 5, 0.2) 0px 1px 0px; font-size: 16px; font-weight: 600; padding: 0 16px; margin-bottom: 10px; }
  .superbo .colNav .e { height: 44px; margin: 0 8px 2px 8px; border: none; border-radius: 4px; font-size: 15px; color: var(--white-interactive); padding: 0 10px; display: flex; align-items: center; cursor: pointer }
  .superbo .colNav .e.active { background: rgba(79,84,92,0.32) }


  .superbo .colMain { }
  .superbo .colMain .title { height: 48px; display: flex; align-items: center; box-shadow: rgba(4, 4, 5, 0.2) 0px 1px 0px; font-size: 16px; font-weight: 600; padding: 0 16px; margin-bottom: 10px; }
  
  .superbo .colRight { width: 230px; flex: 0 0 auto; background: #2f3136 }
  .superbo .colRight .title { height: 48px; display: flex; align-items: center; box-shadow: rgba(4, 4, 5, 0.2) 0px 1px 0px; font-size: 16px; font-weight: 600; padding: 0 16px; margin-bottom: 10px; }
  .superbo .colRight .e { height: 38px; margin: 0 8px 2px 8px; border: none; border-radius: 4px; font-weight: 400; font-size: 15px; color: var(--white-interactive); padding: 0 10px;
    background: rgba(79,84,92,0.32); justify-content: space-between;
    display: flex; align-items: center }
  .superbo .colRight .e span { font-weight: 600; padding-right: 0px; color: var(--orange); font-size: 17px }
  .superbo .colRight .e span .variation { font-style: normal; display: inline-flex; align-items: center; justify-content: center; margin-left: 12px; font-size: 15px; font-weight: 500; color: var(--boVert);
    min-width: 14px; min-width: 24px; height: 24px; background: #444; border-radius: 6px;
  }
  .superbo .colRight .e span .variation[data-negative] { color: var(--boRed) }
  .superbo .colRight .sub { font-size: 15px; font-weight: 600; color: var(--white-interactive); padding-left: 16px; height: 48px; display: flex; align-items: center; margin-top: 12px; }
  .superbo .colRight .sub:nth-child(1) { margin-top: 0; }

  .actif:before { content: 'Non'; color: var(--boRed) }
  .actif[data-active]:before { content: 'Oui'; color: var(--boVert) }
  
  .btn { background: #2f3136; display: inline-flex; padding: 4px 6px; cursor: pointer }


  .set_user_boutique { margin-top: 36px; display: flex; padding-bottom: 36px; }
  .set_user_boutique * { margin-right: 16px; }
  .set_user_boutique *:last-child { margin-right: 0; }

  @media only screen and (min-width: 800px) {
    .superbo .colMain { width: 100%; }
  }

</style>